<template>
<div :style="style">
<svg width="100%" height="100%" viewBox="0 0 3508 1418" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;">
    <!-- <g transform="matrix(3.72601,0,0,2.65889,-1167.11,-2980.56)">
        <path d="M532.744,1329.51L466.66,1157.09L688.806,1242.36L768.966,1122.87L838.538,1237.82L1035.33,1152.84L1012.54,1309.74L1219.67,1349.74L1011.7,1414.85L1084.88,1574.25L913.628,1502.78L824.105,1649.9L718.172,1509.88L535.987,1632.36L561.411,1471.93L357.833,1411.51L532.744,1329.51Z" style="fill:rgb(255,205,47);"/>
        <path d="M532.744,1329.51L466.66,1157.09L688.806,1242.36L768.966,1122.87L838.538,1237.82L1035.33,1152.84L1012.54,1309.74L1219.67,1349.74L1011.7,1414.85L1084.88,1574.25L913.628,1502.78L824.105,1649.9L718.172,1509.88L535.987,1632.36L561.411,1471.93L357.833,1411.51L532.744,1329.51ZM534.518,1336.94C536.081,1336.21 537.334,1334.55 537.942,1332.4C538.549,1330.25 538.45,1327.84 537.67,1325.8C537.67,1325.8 494.164,1212.29 477.752,1169.47C524.732,1187.5 687.329,1249.91 687.329,1249.91C689.326,1250.68 691.462,1249.84 692.88,1247.72L768.751,1134.63C768.751,1134.63 834.274,1242.89 834.274,1242.89C835.722,1245.28 838.047,1246.23 840.185,1245.31L1027.97,1164.22C1027.97,1164.22 1007.06,1308.18 1007.06,1308.18C1006.75,1310.3 1007.09,1312.51 1007.98,1314.27C1008.87,1316.04 1010.25,1317.21 1011.78,1317.51L1188.19,1351.57C1188.19,1351.57 1010.48,1407.2 1010.48,1407.2C1008.7,1407.76 1007.22,1409.5 1006.53,1411.87C1005.83,1414.24 1006.01,1416.93 1007,1419.08C1007,1419.08 1052.93,1519.13 1072.02,1560.7C1072.02,1560.7 915.224,1495.27 915.224,1495.27C913.103,1494.38 910.809,1495.33 909.373,1497.69L823.665,1638.55C823.665,1638.55 722.009,1504.18 722.009,1504.18C720.326,1501.96 717.839,1501.42 715.754,1502.82C715.754,1502.82 582.549,1592.37 543.939,1618.32C543.939,1618.32 566.869,1473.63 566.869,1473.63C567.193,1471.59 566.92,1469.45 566.113,1467.69C565.305,1465.93 564.029,1464.7 562.569,1464.27L379.086,1409.81C379.086,1409.81 534.518,1336.94 534.518,1336.94Z"/>
    </g> -->
    <g transform="matrix(13.2464,-1.15993,2.57944,17.9228,-12944.6,-22548.2)">
        <g transform="matrix(50,0,0,50,716.997,1370.25)">
            <path d="M0.46,-0.135L0.46,-0L0.026,-0L0.026,-0.113C0.155,-0.323 0.231,-0.453 0.256,-0.503C0.28,-0.553 0.292,-0.592 0.292,-0.62C0.292,-0.642 0.288,-0.658 0.281,-0.668C0.274,-0.679 0.263,-0.684 0.248,-0.684C0.233,-0.684 0.221,-0.678 0.214,-0.667C0.207,-0.655 0.203,-0.632 0.203,-0.597L0.203,-0.521L0.026,-0.521L0.026,-0.55C0.026,-0.595 0.029,-0.629 0.033,-0.655C0.038,-0.681 0.049,-0.706 0.067,-0.73C0.085,-0.755 0.108,-0.774 0.137,-0.787C0.165,-0.799 0.2,-0.806 0.24,-0.806C0.318,-0.806 0.378,-0.786 0.418,-0.747C0.458,-0.708 0.478,-0.659 0.478,-0.6C0.478,-0.554 0.467,-0.507 0.444,-0.456C0.421,-0.406 0.355,-0.299 0.244,-0.135L0.46,-0.135Z" style="fill-rule:nonzero;"/>
        </g>
        <g transform="matrix(50,0,0,50,741.093,1370.25)">
            <path d="M0.438,-0.791L0.438,-0.274L0.494,-0.274L0.494,-0.139L0.438,-0.139L0.438,-0L0.24,-0L0.24,-0.139L0.006,-0.139L0.006,-0.274L0.176,-0.791L0.438,-0.791ZM0.24,-0.274L0.24,-0.61L0.153,-0.274L0.24,-0.274Z" style="fill-rule:nonzero;"/>
        </g>
        <g transform="matrix(50,0,0,50,766.069,1370.25)">
            <path d="M0.232,-0.791L0.232,-0.613C0.249,-0.628 0.268,-0.64 0.287,-0.648C0.307,-0.656 0.328,-0.66 0.351,-0.66C0.385,-0.66 0.414,-0.651 0.439,-0.633C0.463,-0.616 0.478,-0.595 0.483,-0.573C0.489,-0.55 0.491,-0.508 0.491,-0.447L0.491,-0L0.294,-0L0.294,-0.456C0.294,-0.494 0.292,-0.518 0.288,-0.529C0.285,-0.54 0.276,-0.545 0.263,-0.545C0.251,-0.545 0.243,-0.539 0.239,-0.528C0.235,-0.517 0.232,-0.495 0.232,-0.462L0.232,-0L0.035,-0L0.035,-0.791L0.232,-0.791Z" style="fill-rule:nonzero;"/>
        </g>
        <g transform="matrix(50,0,0,50,801.079,1370.25)">
            <path d="M0.041,-0.791L0.195,-0.791C0.294,-0.791 0.361,-0.786 0.396,-0.777C0.431,-0.768 0.458,-0.753 0.476,-0.732C0.494,-0.711 0.506,-0.688 0.51,-0.662C0.515,-0.637 0.517,-0.587 0.517,-0.512L0.517,-0.235C0.517,-0.164 0.514,-0.116 0.507,-0.093C0.5,-0.069 0.489,-0.05 0.472,-0.036C0.456,-0.023 0.435,-0.013 0.411,-0.008C0.386,-0.003 0.349,-0 0.3,-0L0.041,-0L0.041,-0.791ZM0.247,-0.655L0.247,-0.135C0.276,-0.135 0.294,-0.141 0.301,-0.153C0.308,-0.165 0.312,-0.197 0.312,-0.25L0.312,-0.557C0.312,-0.593 0.31,-0.616 0.308,-0.626C0.306,-0.636 0.301,-0.643 0.292,-0.648C0.284,-0.653 0.269,-0.655 0.247,-0.655Z" style="fill-rule:nonzero;"/>
        </g>
        <g transform="matrix(50,0,0,50,828.716,1370.25)">
            <path d="M0.211,-0.397L0.026,-0.397L0.026,-0.44C0.026,-0.491 0.032,-0.529 0.044,-0.556C0.055,-0.584 0.078,-0.608 0.113,-0.628C0.148,-0.649 0.193,-0.66 0.249,-0.66C0.316,-0.66 0.366,-0.648 0.4,-0.624C0.434,-0.601 0.454,-0.572 0.461,-0.537C0.468,-0.503 0.471,-0.432 0.471,-0.325L0.471,-0L0.279,-0L0.279,-0.058C0.267,-0.035 0.252,-0.017 0.233,-0.006C0.214,0.006 0.191,0.012 0.165,0.012C0.13,0.012 0.098,0.002 0.07,-0.017C0.041,-0.037 0.026,-0.079 0.026,-0.145L0.026,-0.198C0.026,-0.246 0.034,-0.279 0.049,-0.297C0.065,-0.314 0.103,-0.335 0.163,-0.358C0.228,-0.384 0.263,-0.401 0.267,-0.41C0.272,-0.418 0.274,-0.436 0.274,-0.463C0.274,-0.497 0.271,-0.519 0.266,-0.53C0.261,-0.54 0.253,-0.545 0.241,-0.545C0.228,-0.545 0.22,-0.541 0.216,-0.532C0.213,-0.523 0.211,-0.501 0.211,-0.465L0.211,-0.397ZM0.274,-0.308C0.242,-0.285 0.224,-0.266 0.219,-0.25C0.214,-0.234 0.211,-0.212 0.211,-0.183C0.211,-0.149 0.214,-0.127 0.218,-0.118C0.222,-0.108 0.231,-0.103 0.244,-0.103C0.257,-0.103 0.265,-0.107 0.268,-0.115C0.272,-0.122 0.274,-0.142 0.274,-0.175L0.274,-0.308Z" style="fill-rule:nonzero;"/>
        </g>
        <g transform="matrix(50,0,0,50,853.911,1370.25)">
            <path d="M0.448,-0.648L0.392,-0.179C0.383,-0.105 0.375,-0.054 0.369,-0.027C0.363,-0 0.353,0.022 0.339,0.04C0.325,0.057 0.306,0.07 0.281,0.078C0.256,0.087 0.216,0.091 0.162,0.091L0.042,0.091L0.042,-0.013C0.073,-0.013 0.092,-0.014 0.101,-0.017C0.11,-0.02 0.114,-0.027 0.114,-0.038C0.114,-0.043 0.11,-0.066 0.102,-0.106L-0.006,-0.648L0.173,-0.648L0.238,-0.21L0.27,-0.648L0.448,-0.648Z" style="fill-rule:nonzero;"/>
        </g>
        <g transform="matrix(50,0,0,50,876.323,1370.25)">
            <path d="M0.236,-0.648L0.232,-0.591C0.25,-0.614 0.269,-0.631 0.291,-0.643C0.312,-0.654 0.335,-0.66 0.36,-0.66C0.39,-0.66 0.417,-0.651 0.439,-0.635C0.462,-0.619 0.475,-0.6 0.481,-0.578C0.486,-0.557 0.489,-0.52 0.489,-0.469L0.489,-0.196C0.489,-0.137 0.486,-0.095 0.479,-0.07C0.472,-0.045 0.457,-0.025 0.436,-0.01C0.414,0.004 0.388,0.012 0.357,0.012C0.333,0.012 0.31,0.006 0.29,-0.005C0.269,-0.017 0.25,-0.034 0.232,-0.056L0.232,0.092L0.035,0.092L0.035,-0.648L0.236,-0.648ZM0.292,-0.459C0.292,-0.5 0.291,-0.524 0.288,-0.532C0.285,-0.541 0.277,-0.545 0.263,-0.545C0.25,-0.545 0.242,-0.54 0.238,-0.53C0.234,-0.521 0.232,-0.497 0.232,-0.459L0.232,-0.191C0.232,-0.152 0.234,-0.127 0.238,-0.118C0.242,-0.108 0.251,-0.103 0.264,-0.103C0.276,-0.103 0.284,-0.108 0.287,-0.116C0.29,-0.125 0.292,-0.147 0.292,-0.182L0.292,-0.459Z" style="fill-rule:nonzero;"/>
        </g>
        <g transform="matrix(50,0,0,50,902.275,1370.25)">
            <path d="M0.211,-0.397L0.026,-0.397L0.026,-0.44C0.026,-0.491 0.032,-0.529 0.044,-0.556C0.055,-0.584 0.078,-0.608 0.113,-0.628C0.148,-0.649 0.193,-0.66 0.249,-0.66C0.316,-0.66 0.366,-0.648 0.4,-0.624C0.434,-0.601 0.454,-0.572 0.461,-0.537C0.468,-0.503 0.471,-0.432 0.471,-0.325L0.471,-0L0.279,-0L0.279,-0.058C0.267,-0.035 0.252,-0.017 0.233,-0.006C0.214,0.006 0.191,0.012 0.165,0.012C0.13,0.012 0.098,0.002 0.07,-0.017C0.041,-0.037 0.026,-0.079 0.026,-0.145L0.026,-0.198C0.026,-0.246 0.034,-0.279 0.049,-0.297C0.065,-0.314 0.103,-0.335 0.163,-0.358C0.228,-0.384 0.263,-0.401 0.267,-0.41C0.272,-0.418 0.274,-0.436 0.274,-0.463C0.274,-0.497 0.271,-0.519 0.266,-0.53C0.261,-0.54 0.253,-0.545 0.241,-0.545C0.228,-0.545 0.22,-0.541 0.216,-0.532C0.213,-0.523 0.211,-0.501 0.211,-0.465L0.211,-0.397ZM0.274,-0.308C0.242,-0.285 0.224,-0.266 0.219,-0.25C0.214,-0.234 0.211,-0.212 0.211,-0.183C0.211,-0.149 0.214,-0.127 0.218,-0.118C0.222,-0.108 0.231,-0.103 0.244,-0.103C0.257,-0.103 0.265,-0.107 0.268,-0.115C0.272,-0.122 0.274,-0.142 0.274,-0.175L0.274,-0.308Z" style="fill-rule:nonzero;"/>
        </g>
        <g transform="matrix(50,0,0,50,927.47,1370.25)">
            <path d="M0.438,-0.441L0.263,-0.441L0.263,-0.476C0.263,-0.506 0.261,-0.525 0.258,-0.533C0.255,-0.541 0.246,-0.545 0.232,-0.545C0.221,-0.545 0.213,-0.541 0.208,-0.534C0.202,-0.527 0.199,-0.516 0.199,-0.501C0.199,-0.481 0.201,-0.467 0.203,-0.457C0.206,-0.448 0.214,-0.438 0.228,-0.427C0.242,-0.416 0.271,-0.4 0.313,-0.378C0.371,-0.35 0.408,-0.324 0.426,-0.299C0.444,-0.275 0.453,-0.239 0.453,-0.191C0.453,-0.139 0.446,-0.099 0.433,-0.072C0.419,-0.045 0.396,-0.024 0.364,-0.01C0.332,0.004 0.293,0.012 0.248,0.012C0.198,0.012 0.155,0.004 0.119,-0.012C0.084,-0.027 0.059,-0.049 0.046,-0.075C0.033,-0.102 0.026,-0.142 0.026,-0.196L0.026,-0.228L0.201,-0.228L0.201,-0.187C0.201,-0.152 0.203,-0.129 0.207,-0.119C0.212,-0.108 0.22,-0.103 0.233,-0.103C0.247,-0.103 0.257,-0.107 0.263,-0.114C0.268,-0.121 0.271,-0.135 0.271,-0.158C0.271,-0.189 0.267,-0.208 0.26,-0.216C0.253,-0.224 0.215,-0.247 0.146,-0.285C0.088,-0.318 0.052,-0.347 0.04,-0.374C0.027,-0.4 0.021,-0.432 0.021,-0.468C0.021,-0.52 0.027,-0.558 0.041,-0.583C0.055,-0.607 0.078,-0.626 0.111,-0.64C0.143,-0.653 0.181,-0.66 0.225,-0.66C0.268,-0.66 0.304,-0.654 0.334,-0.643C0.364,-0.632 0.387,-0.618 0.404,-0.6C0.42,-0.582 0.429,-0.566 0.433,-0.55C0.436,-0.535 0.438,-0.511 0.438,-0.479L0.438,-0.441Z" style="fill-rule:nonzero;"/>
        </g>
        <g transform="matrix(50,0,0,50,951.006,1370.25)">
            <path d="M0.438,-0.441L0.263,-0.441L0.263,-0.476C0.263,-0.506 0.261,-0.525 0.258,-0.533C0.255,-0.541 0.246,-0.545 0.232,-0.545C0.221,-0.545 0.213,-0.541 0.208,-0.534C0.202,-0.527 0.199,-0.516 0.199,-0.501C0.199,-0.481 0.201,-0.467 0.203,-0.457C0.206,-0.448 0.214,-0.438 0.228,-0.427C0.242,-0.416 0.271,-0.4 0.313,-0.378C0.371,-0.35 0.408,-0.324 0.426,-0.299C0.444,-0.275 0.453,-0.239 0.453,-0.191C0.453,-0.139 0.446,-0.099 0.433,-0.072C0.419,-0.045 0.396,-0.024 0.364,-0.01C0.332,0.004 0.293,0.012 0.248,0.012C0.198,0.012 0.155,0.004 0.119,-0.012C0.084,-0.027 0.059,-0.049 0.046,-0.075C0.033,-0.102 0.026,-0.142 0.026,-0.196L0.026,-0.228L0.201,-0.228L0.201,-0.187C0.201,-0.152 0.203,-0.129 0.207,-0.119C0.212,-0.108 0.22,-0.103 0.233,-0.103C0.247,-0.103 0.257,-0.107 0.263,-0.114C0.268,-0.121 0.271,-0.135 0.271,-0.158C0.271,-0.189 0.267,-0.208 0.26,-0.216C0.253,-0.224 0.215,-0.247 0.146,-0.285C0.088,-0.318 0.052,-0.347 0.04,-0.374C0.027,-0.4 0.021,-0.432 0.021,-0.468C0.021,-0.52 0.027,-0.558 0.041,-0.583C0.055,-0.607 0.078,-0.626 0.111,-0.64C0.143,-0.653 0.181,-0.66 0.225,-0.66C0.268,-0.66 0.304,-0.654 0.334,-0.643C0.364,-0.632 0.387,-0.618 0.404,-0.6C0.42,-0.582 0.429,-0.566 0.433,-0.55C0.436,-0.535 0.438,-0.511 0.438,-0.479L0.438,-0.441Z" style="fill-rule:nonzero;"/>
        </g>
    </g>
  </svg>
  </div>
</template>

<script>
export default {
    props: {
        width: {
            type: [Number, String],
            default: "100%",
        },
        height: {
            type: [Number, String],
            default: "100%",
        },
    },
    computed: {
        style() {
            return {
                width: this.width,
                height: this.height,
            };
        },
    },
}
</script>