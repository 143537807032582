<template>
  <v-card>
    <v-toolbar color="titleBar" dark flat dense>
      <v-spacer />
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn :disabled="loading" v-on="on" @click="getItems()" icon>
            <v-icon>mdi-reload</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("reload") }}</span>
      </v-tooltip>
      <wiki slug="services-tv-end-overview" />
    </v-toolbar>
    <v-progress-linear :active="loading" indeterminate />
    <v-card-text>
      <v-row>
        <v-col cols="3">
          <v-list>
            <v-subheader>{{ $t("TV PRODUCTS") }}</v-subheader>
            <v-list-item-group v-model="selected" mandatory>
              <v-list-item
                v-for="(item, index) in items"
                :key="item.product_id"
              >
                <v-list-item-content>
                  <v-list-item-title>
                    {{ item.product_name }}
                  </v-list-item-title>
                  <v-list-item-subtitle v-if="item.product_description">
                    {{ item.product_description }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-col>
        <v-divider vertical></v-divider>
        <v-col cols="9">
          <v-card outlined>
            <v-card-text v-if="loading"> {{ $t("loading...") }} </v-card-text>
            <v-card-text v-else-if="!tvProduct.id">{{
              $t("No Data Loaded")
            }}</v-card-text>
            <v-card-text v-else>
              <v-simple-table dense class="pb-2 mt-4">
                <tbody>
                  <tr>
                    <th>{{ $t("state") }}</th>
                    <td>
                      <v-chip
                        :class="activeTv ? 'warning' : 'success'"
                        class="my-2 mr-2"
                        small
                      >
                        {{ activeTv ? $t("inactive") : $t("active") }}
                      </v-chip>
                      <v-chip
                        v-if="runningWorkers"
                        class="info my-2 mr-2"
                        small
                      >
                        {{ $t("worker running") }}
                      </v-chip>
                    </td>
                  </tr>
                  <tr>
                    <th>{{ $t("billing_start_date") }}</th>
                    <td class="monospace">{{ formatDate(tvProduct.billing_start_date) }}</td>
                  </tr>
                  <tr v-if="tvProduct.end_date">
                    <th>{{ $t("end_date") }}</th>
                    <td class="monospace">{{ formatDate(tvProduct.end_date) }}</td>
                  </tr>
                  <tr>
                    <th>{{ $t("web tv") }}</th>
                    <td>
                      <a href="https://iwaytv.ch" target="_blank"
                        >https://iwaytv.ch</a
                      >
                    </td>
                  </tr>
                  <tr>
                    <th>{{ $t("username") }}</th>
                    <td>{{ tvProduct.username }}</td>
                  </tr>
                  <tr>
                    <th>{{ $t("password") }}</th>
                    <td>{{ tvProduct.password }}</td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-card-text>
          </v-card>

          <v-card-title v-if="!loading && tvProduct.id">
            {{ $t("Subscription") }}
          </v-card-title>

            <v-card
              flat
              outlined
              class="d-flex flex-wrap pa-2"
              v-if="!loading && tvProduct.id && subscriptions.length > 0"
            >
              <template v-for="subscription in subscriptions">
                <v-card
                outlined
                class="d-flex flex-column ma-2"
                width="260"
                min-height="100"
                v-if="activeSubscriptionPos <= subscription.pos"
                v-bind:key="subscription.id"

                >

                <v-toolbar flat>
                    {{ subscription.title_cart }}

                    <v-spacer></v-spacer>
                    <v-chip small
                    color="success"
                    v-if="
                      subscription.product_numbers.find((el) =>
                        tvProduct.subscription === el
                      )
                    ">
                  {{ $t("active") }}
                </v-chip>

                  </v-toolbar>

              <v-spacer class="mb-auto"></v-spacer>
              <v-card-actions v-if="!loading && !runningWorkers">
                <span
                  class="monospace text-no-wrap"
                  v-if="subscription.price_rec_incl > 0"
                >
                  {{ formatMoney(subscription.price_rec_incl) + $t("/month") }}
                </span>
                <span class="monospace text-no-wrap" v-else>
                  {{ formatMoney(subscription.price_fix_incl) }}
                </span>

                <v-spacer />
                <template v-if="activeSubscriptionPos != subscription.pos">
                  <v-btn v-if="gfoSubscription==subscription.id" @click="gfoSubscription=null" color="secondary" text>
                    {{ $t("cancel") }}
                  </v-btn>
                  <v-btn v-else @click="gfoSubscription=subscription.id" :disabled="!!subscription.product_numbers.find((el) =>tvProduct.subscription === el)" text>
                    {{ $t("upgrade") }}
                  </v-btn>
                </template>


              </v-card-actions>
              </v-card>

              </template>
            </v-card>



          <v-card-title v-if="!loading && tvProduct.id">
            {{ $t("Additional Packages") }}
            <v-btn
              class="ml-3"
              depressed
              href="https://www.iway.ch/tv/senderliste/"
              target="_blank"
              outlined
            >
              <v-icon left> mdi-television-box </v-icon>
              {{ $t("Channel List") }}
            </v-btn>
          </v-card-title>
          <v-card
            flat
            outlined
            class="d-flex flex-wrap pa-2"
            v-if="!loading && tvProduct.id"
          >
            <v-card
              v-for="option in options"
              :key="option.id"
              class="d-flex flex-column ma-2"
              width="260"
              min-height="200"
            >
              <v-card-subtitle>
                {{ option.title }}
              </v-card-subtitle>
              <v-card-text
                v-if="
                  option.product_numbers.find((el) =>
                    tvProduct.options.includes(el)
                  )
                "
              >
                <v-chip class="success ml-2" small>
                  {{ $t("active") }}
                </v-chip>
                <v-chip
                  v-if="option.product_numbers.find(el => el in (tvProduct.mutations.deactivate || []))"
                  class="warning ml-2"
                  small
                >
                  {{
                    $t("Active until ") +
                    formatDateTime(tvProduct.mutations.deactivate[option.product_numbers.find(el => el in (tvProduct.mutations.deactivate || []))])
                  }}
                </v-chip>
              </v-card-text>

              <v-spacer class="mb-auto"></v-spacer>
              <v-divider></v-divider>
              <v-card-actions>
                <span
                  class="monospace text-no-wrap"
                  v-if="option.price_rec_incl > 0"
                >
                  {{ formatMoney(option.price_rec_incl) + $t("/month") }}
                </span>
                <span class="monospace text-no-wrap" v-else>
                  {{ formatMoney(option.price_fix_incl) }}
                </span>

                <v-spacer></v-spacer>

                <!-- <v-btn
                  v-if="terminations.includes(option.id)"
                  text
                  @click="terminations = terminations.filter(item => item !== option.id)"
                  color="secondary"
                  :disabled="runningWorkers"
                  >{{ $t("cancel") }}</v-btn>
                <v-btn
                  :disabled="runningWorkers"
                  v-else-if="option.product_numbers.find(el => tvProduct.options.includes(el)) && !option.product_numbers.find(el => el in (tvProduct.mutations.deactivate || []))"
                  text
                  @click="terminations.push(option.id)"
                  >{{ $t("terminate") }}</v-btn> -->
                <v-btn
                  v-if="gfoOptionsOrder.includes(option.id)"
                  text
                  @click="gfoOptionsOrder = gfoOptionsOrder.filter((item) => item !== option.id)"
                  color="secondary"
                  :disabled="runningWorkers"
                  >{{ $t("cancel") }}</v-btn
                >
                <v-btn
                  :disabled="
                    runningWorkers ||
                    !!option.product_numbers.find((el) =>
                      tvProduct.options.includes(el)
                    )
                  "
                  v-else
                  text
                  @click="gfoOptionsOrder.push(option.id)"
                  >{{ $t("order") }}</v-btn
                >
              </v-card-actions>
            </v-card>
            <v-card
              class="d-flex flex-column ma-2"
              width="260"
              min-height="200"
              v-if="!tvProduct.options.includes('0801211') && options.find((el) => el.id === 1855)">
              <v-card-subtitle>
                iWay MySports Pro Daypass
              </v-card-subtitle>
              <v-card-text>
                <day-pass width="60%" ></day-pass>
              </v-card-text>
              <v-spacer class="mb-auto"></v-spacer>
              <v-divider></v-divider>
              <v-card-actions>
                <span class="monospace text-no-wrap">
                  {{ formatMoney(15) }}
                </span>
                <v-spacer></v-spacer>
                <v-btn
                  v-if="gfoDayPassOrder.includes(1855)"
                  text
                  @click="gfoDayPassOrder = gfoDayPassOrder.filter((item) => item !== 1855)"
                  color="secondary"
                  :disabled="runningWorkers"
                  >{{ $t("cancel") }}</v-btn>
                <v-btn :disabled="runningWorkers" v-else @click="gfoDayPassOrder.push(1855)" text>{{ $t("order") }}</v-btn>
              </v-card-actions>
            </v-card>
          </v-card>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-dialog v-model="dialogOrder" width="700" elevation="0">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="primary"
                  :disabled="loading || forbidden"
                  v-on="on"
                >
                  {{ $t("checkout") }}
                </v-btn>
              </template>

              <v-card>
                <v-card-title v-if="gfoSubscription">
                  {{ $t("Upgrade Summary") }}
                </v-card-title>

                <v-card-text v-if="gfoSubscription">
                  <v-simple-table>
                    <thead>
                      <tr>
                        <th class="text-left">
                          {{ $t("Subscription name") }}
                        </th>
                        <th class="text-left">
                          {{ $t("cost") }}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          {{ subscriptions.find((el) => el.id === gfoSubscription).title_cart }}
                        </td>
                        <td
                          v-if="
                            subscriptions.find((el) => el.id === gfoSubscription)
                              .price_rec_incl > 0
                          "
                          class="monospace text-no-wrap"
                        >
                          {{
                            formatMoney(
                              subscriptions.find((el) => el.id === gfoSubscription)
                                .price_rec_incl
                            ) + $t("/month")
                          }}
                        </td>
                        <td v-else class="monospace text-no-wrap">
                          {{
                            formatMoney(
                              subscriptions.find((el) => el.id === gfoSubscription)
                                .price_fix_incl
                            )
                          }}
                        </td>
                      </tr>
                    </tbody>
                  </v-simple-table>
                </v-card-text>

                <v-card-title v-if="gfoOptionsOrder.length > 0 || gfoDayPassOrder.length > 0">
                  {{ $t("Order Summary") }}
                </v-card-title>

                <v-card-text v-if="gfoOptionsOrder.length > 0 || gfoDayPassOrder.length > 0">
                  {{ $t("OrderInfotext") }}

                  <v-simple-table>
                    <thead>
                      <tr>
                        <th class="text-left">
                          {{ $t("package name") }}
                        </th>
                        <th class="text-left">
                          {{ $t("cost") }}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="item in gfoOptionsOrder" :key="item">
                        <td>
                          {{ options.find((el) => el.id === item).title_cart }}
                        </td>
                        <td
                          v-if="
                            options.find((el) => el.id === item)
                              .price_rec_incl > 0
                          "
                          class="monospace text-no-wrap"
                        >
                          {{
                            formatMoney(
                              options.find((el) => el.id === item)
                                .price_rec_incl
                            ) + $t("/month")
                          }}
                        </td>
                        <td v-else class="monospace text-no-wrap">
                          {{
                            formatMoney(
                              options.find((el) => el.id === item)
                                .price_fix_incl
                            )
                          }}
                        </td>
                      </tr>
                      <tr v-for="item in gfoDayPassOrder" :key="item">
                        <td>
                          {{ item == 1855 ?  "MySports Pro Daypass" : "Unknown" }}
                        </td>
                        <td class="monospace text-no-wrap">
                          {{
                            item == 1855 ?  formatMoney(15) : "Unknown"
                          }}
                        </td>
                      </tr>
                    </tbody>
                  </v-simple-table>
                </v-card-text>

                <!-- <v-card-title  v-if="terminations.length > 0">
                  {{ $t("Termination Summary") }}
                </v-card-title>

                <v-card-text v-if="terminations.length > 0">
                  {{ $t("TerminationInfotext") }}

                  <v-simple-table>
                      <thead>
                        <tr>
                          <th class="text-left">
                            {{ $t("package name") }}
                          </th>
                          <th class="text-left">
                            {{ $t("cost") }}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="item in terminations"
                          :key="item"
                        >
                          <td>{{ options.find(el => el.id === item).title_cart }}</td>
                          <td v-if="options.find(el => el.id === item).price_rec_incl > 0" class="monospace text-no-wrap">{{ formatMoney(options.find(el => el.id === item).price_rec_incl) + $t("/month") }}</td>
                          <td v-else class="monospace text-no-wrap">{{ formatMoney(options.find(el => el.id === item).price_fix_incl) }}</td>

                        </tr>
                      </tbody>
                    </v-simple-table>
                </v-card-text> -->

                <v-card-actions>
                  <v-checkbox
                    v-if="confirmTosRequired"
                    v-model="confirmTos"
                    :label="$t('accept mysports tos')"
                  />
                  <a
                    v-if="confirmTosRequired"
                    class="ml-1"
                    :href="$config.mySportsAGB"
                    target="_blank"
                    >{{ $t("mysports tos") }}</a
                  >
                  <v-spacer></v-spacer>
                  <v-btn text @click="dialogOrder = false">
                    {{ $t("cancel") }}
                  </v-btn>
                  <v-btn
                    color="primary"
                    :disabled="confirmTosRequired && !confirmTos"
                    @click="
                      dialogOrder = false;
                      commitOrder();
                    "
                  >
                    {{ $t("confirm") }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-card-actions>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import formatDate from "@/utils/mixins/formatDate";
import formatDateTime from "@/utils/mixins/formatDateTime";
import formatMoney from "@/utils/mixins/formatMoney";
import isMobile from "@/utils/mixins/isMobile";
import checkWorkers from "@/utils/mixins/checkWorkers";
import Wiki from "@/components/basics/Wiki";
import DayPass from "@/components/basics/assets/DayPass";

const emptyProduct = {
  product_name: null,
  billing_start_date: null,
  description: null,
  blocked: null,
  subscriptions: null,
  options: [],
  username: null,
  password: null,
  account_name_override: null,
  zip_override: "",
  serial_numbers: [],
  end_date: null,
};

// checks if worker would block product update
var checkWorkerCallback = function (worker) {
  let matched = [
    "workerticketzumatvupdate",
    "workerticketzumatvcreate",
    "workerticketproductblock",
    "workerticketproducttermination",
  ].includes(worker.worker_code);
  return !matched;
};

export default {
  name: "ZumaTvEnd",
  mixins: [formatDate, formatDateTime, formatMoney, isMobile, checkWorkers],
  props: {
    productId: {
      type: [String, Number],
      default: null,
    },
  },
  components: {
    Wiki,
    DayPass,
  },
  data: () => ({
    loadingProduct: false,
    loadingItems: false,
    loadingOptions: false,
    loadingWorker: false,
    selected: 0,
    items: [],
    tvProduct: { ...emptyProduct },
    runningWorkers: false,
    gfoSubscription: null,
    gfoOptionsOrder: [],
    gfoDayPassOrder: [],
    terminations: [],
    dialogOrder: false,
    options: [],
    subscriptions: [],
    confirmTos: false,
  }),
  watch: {
    productId: function () {},
    selected: function () {
      if (this.items.length > 0) this.getProduct(this.items[this.selected].id);
    },
  },
  computed: {
    activeTv: function () {
      let currentDate = new Date();
      if(this.tvProduct.blocked || this.tvProduct.end_date < currentDate )
        return false
      return true
    },
    activeSubscriptionPos: function () {
      if (!this.tvProduct) {
        return null
      }
      var subscription = this.subscriptions.find((el) => el.product_numbers.includes(this.tvProduct.subscription))
      return subscription ? subscription.pos : 0;
    },
    confirmTosRequired: function () {
      if (!this.loadingOptions) {
        let boolorder = this.gfoOptionsOrder.find((el) =>
          this.options.find(
            (opt) =>
              opt.id === el && opt.title_cart.toLowerCase().includes("mysports")
          )
        );
        let booldaypass = this.gfoDayPassOrder.find((el) =>
          this.options.find(
            (opt) =>
              opt.id === el && opt.title_cart.toLowerCase().includes("mysports")
          )
        );
        return boolorder || booldaypass;
      }
      return false;
    },
    loading: function () {
      return (
        this.loadingProduct ||
        this.loadingItems ||
        this.loadingOptions ||
        this.loadingWorker
      );
    },
    forbidden: function () {
      return (
        !(this.gfoOptionsOrder.length > 0 || this.gfoSubscription || this.gfoDayPassOrder.length > 0) ||
        this.runningWorkers
      );
    },
  },
  methods: {
    getOptions() {
      var that = this;
      this.loadingOptions = true;
      this.$http
        .get("services/tv/gfo-options")
        .then((response) => {
          that.subscriptions = response.data.subscriptions.sort((a, b) => a.id - b.id);
          that.options = response.data.options.sort((a, b) => a.id - b.id);
        })
        .catch((e) => {
          console.error(e);
        })
        .finally(function () {
          that.loadingOptions = false;
        });
    },
    getItems() {
      var that = this;
      this.loadingItems = true;
      this.$http
        .get("services/tv")
        .then((response) => {
          that.items = response.data;
        })
        .catch((e) => {
          console.error(e);
        })
        .finally(function () {
          that.loadingItems = false;
          that.getProduct(that.items[that.selected].id);
        });
    },
    getProduct(id) {
      var that = this;
      this.loadingProduct = true;
      this.$http
        .get("services/tv/" + id)
        .then((response) => {
          that.tvProduct = response.data;
          that.getWorkerStatus();
        })
        .catch((e) => {
          console.error(e);
        })
        .finally(function () {
          that.loadingProduct = false;
        });
    },
    getWorkerStatus() {
      var that = this;
      this.loadingWorker = true;
      this.$http
        .get("products/" + this.tvProduct.product_id)
        .then((response) => {
          that.checkWorkers(
            response.data.workers,
            checkWorkerCallback,
            "runningWorkers",
            "loadingWorker"
          );
        })
        .catch((err) => {
          that.$store.commit("setSystemError", {
            msg: err.message,
            title: err.title,
          });
        });
    },
    commitOrder() {
      var that = this;
      this.loadingOrder = true;
      var product = {
        // gfo_options_terminate: this.terminations,
        gfo_options_order: this.gfoOptionsOrder,
        gfo_subscription: this.gfoSubscription,
        gfo_options_order_daypass: this.gfoDayPassOrder,
      };
      this.$http
        .patch("services/tv/" + this.tvProduct.id, product)
        .then((response) => {
          that.$snotify.success(
            that.$i18n.t("workerCreated", {
              subject: response.data.subject,
            })
          );
          that.getWorkerStatus();
          that.$emit("updated");
        })
        .catch((err) => {
          that.$refs.obs.setErrors(err.data);
          that.showErrors(that.$refs.obs.getUnresolvedErrors(err));
        })
        .finally(function () {
          that.loadingOrder = false;
          that.terminations = [];
          that.gfoOptionsOrder = [];
        });
    },
  },
  mounted: function () {
    this.getItems();
    this.getOptions();
  },
};
</script>

<i18n>
{
  "en": {
    "reload":"Reload",
    "billing_start_date":"First billing date",
    "state": "State",
    "active": "Active",
    "inactive": "Inactive",
    "end_date": "End date",
    "confirm": "Confirm",
    "order": "Order",
    "checkout": "Checkout",
    "upgrade": "Upgrade",
    "terminate": "Terminate",
    "Channel List": "Channel list",
    "Additional Packages": "Additional channel packages",
    "password": "Password",
    "username": "Username",
    "web tv": "Web TV link",
    "TV PRODUCTS": "TV products",
    "loading...": "Loading...",
    "No Data Loaded": "No data loaded",
    "/month": "/month",
    "cost": "Cost",
    "package name": "Package",
    "Termination Summary": "Termination summary",
    "Subscription name": "Subscription",
    "Order Summary": "Order summary",
    "Upgrade Summary": "Upgrade summary",
    "OrderInfotext": "Minimum term is three months from the beginning of the next month",
    "TerminationInfotext": "Channel packages are cancelled at the end of each month, taking into account the minimum term of one month",
    "mysports tos": "terms of service",
    "accept mysports tos": "I accept MySports",
    "worker running": "Ongoing process",
    "workerCreated": "worker {subject} created",
    "Subscription": "Subscription",
    "Active until ": "Active until "
  },
  "de": {
    "reload":"Neu laden",
    "billing_start_date":"Verrechnungsstart",
    "state": "Status",
    "active": "Aktiv",
    "inactive": "Inaktiv",
    "end_date": "Enddatum",
    "confirm": "Bestätigen",
    "order": "Bestellen",
    "checkout": "Zur Kasse",
    "upgrade": "Upgrade",
    "terminate": "Kündigen",
    "Channel List": "Kanal Übersicht",
    "Additional Packages": "Zusätzliche Senderpakete",
    "password": "Passwort",
    "username": "Benutzername",
    "web tv": "Web TV Link",
    "TV PRODUCTS": "TV Produkte",
    "loading...": "Wird geladen...",
    "No Data Loaded": "Keine Daten gefunden",
    "/month": "/Monat",
    "cost": "Kosten",
    "package name": "Senderpaket",
    "Termination Summary": "Kündigungsübersicht",
    "Subscription name": "Abonnement",
    "Order Summary": "Bestellübersicht",
    "Upgrade Summary": "Upgradeübersicht",
    "OrderInfotext": "Mindestlaufzeit für Abos ist drei Monate nach nächstem Monatsbeginn",
    "TerminationInfotext": "Senderpakete werden jeweils zum Monatsende gekündigt, wobei die Mindestlaufzeit von einem Monat berücksichtigt wird",
    "mysports tos": "Geschäftsbedingungen",
    "accept mysports tos": "Ich akzepiere MySports",
    "worker running": "Laufender Auftrag",
    "workerCreated": "Auftrag {subject} angelegt",
    "Subscription": "Abonnement",
    "Active until ": "Aktiv bis "
  }
}
</i18n>
